import {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Table} from "react-bootstrap";
import {formatDateFromString, getFormattedNumber} from "../../commons";

function RegulatorSummary({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation,
                              isHydro
                          }){

    const [regulatorData, setRegulatorData] = useState(null);

    useEffect(() => {
        if(simulationId && simulationInitialValues && typeName) {
            trackPromise(
                fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/' + typeName + '?'
                    + 'aggregation=' + aggregation))
                .then(res => res.json()).then(data => {
                if (data["data"].length === 1) {
                    setRegulatorData({...JSON.parse(data["data"][0]["data"])});
                }
            });
        }

    }, [simulationId, aggregation, typeName, simulationInitialValues]);


    console.log(regulatorData);

    let summaryInfo = null;
    if (regulatorData && viewName === "Regulator's view - Summary"){
        summaryInfo = (
            <div>
                <fieldset>
                    <legend className={"text-left"}>
                        Project's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            {
                                simulation.configuration.projectType === "Storage" &&
                                <th>Arbitrage Profit (€/MWh)</th>
                            }
                            <th>Average price impact (€/MWh)</th>
                            <th>Consumer savings (M€) </th>
                            <th>NPV of consumer savings (M€)</th>
                            <th>NPV of regulator's revenue (M€)</th>
                            <th>Total avoided emissions (Million Ton)</th>
                            <th>Avg avoided emissions (ton/MWh)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {
                                simulation.configuration.projectType === "Storage" &&
                                <td>{getFormattedNumber(regulatorData['Arbitrage Profit'], 1)}</td>
                            }
                            <td>{getFormattedNumber(regulatorData['Average price impact'], 4)}</td>
                            <td>{getFormattedNumber(regulatorData['Savings for consumers']/1000000, 1)}</td>
                            <td>{getFormattedNumber(regulatorData['NPV of savings']/1000000, 1)}</td>
                            <td>{getFormattedNumber(regulatorData['NPV of regulator revenue']/1000000, 1)}</td>
                            <td>{getFormattedNumber(regulatorData['Total avoided emissions' +
                            ' (tons)']/1000000, 3)}</td>
                            <td>{getFormattedNumber(regulatorData['Avg avoided emissions' +
                            ' (ton/MWh)'], 4)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>

                <fieldset style={{marginTop: "20px"}}>
                    <legend className={"text-left"}>
                        Plants' metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Plant</th>
                            <th>Average price received (€/MWh)</th>
                            <th>Captured market price (€/MWh)</th>
                            <th>Regulated avg. subsidy (€/MWh)</th>
                            <th>Average cost (€/MWh)</th>
                            <th>Variable cost (€/MWh)</th>
                            {
                                simulation.configuration.projectType !== "Storage" &&
                                <th>Average yearly production (GWh)</th>
                            }
                            {
                                simulation.configuration.projectType === "Storage" &&
                                <th>Avg Market Price paid (€/MWh)</th>
                            }
                            {
                                simulation.configuration.projectType === "Storage" &&
                                <th>Avg Yearly charged (GWh)</th>
                            }

                            {
                                simulation.configuration.projectType === "Storage" &&
                                <th>Avg Yearly discharged (GWh)</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            simulation.configuration.plants.map((p, idx) => {
                                const plant_index = p['index'];
                                return (<tr key={idx}>
                                            <td>{p['type']}</td>
                                            <td>{getFormattedNumber(regulatorData['Average price' +
                                            ' received']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData['Captured market price']['VAL_' + plant_index], 2)}</td>
                                            <td>
                                                {getFormattedNumber(regulatorData?.['Regulated avg. subsidy']?.['VAL_' + plant_index] ?? 0, 2)}
                                            </td>
                                            <td>{getFormattedNumber(regulatorData['Average cost']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData['Variable cost']['VAL_' + plant_index], 2)}</td>
                                            {
                                                simulation.configuration.projectType !== "Storage" &&
                                                    <td>{getFormattedNumber(regulatorData['Average' +
                                                            ' yearly production (MWh)']['VAL_' + plant_index] / 1000, 1)}</td>
                                            }
                                            {
                                                simulation.configuration.projectType === "Storage" &&
                                                <td>{getFormattedNumber(regulatorData['Averaged Market Price Paid']['VAL_' + plant_index], 2)}</td>
                                            }
                                            {
                                                simulation.configuration.projectType === "Storage" &&
                                                <td>{getFormattedNumber(regulatorData['Average Charged Year GWh']['VAL_' + plant_index], 2)}</td>
                                            }
                                            {
                                                simulation.configuration.projectType === "Storage" &&
                                                <td>{getFormattedNumber(regulatorData['Average Discharged Year GWh']['VAL_' + plant_index], 2)}</td>
                                            }
                                        </tr>)
                            })
                        }
                        </tbody>
                    </Table>
                </fieldset>
            </div>
        )
    }


    if (regulatorData && viewName === "Investor's view - Summary"){
        summaryInfo = (
            <fieldset>
                <legend className={"text-left"}>
                    Project's metrics
                </legend>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>{isHydro ? 'LCOH (€/Kg)' : 'LCOE (€/MWh)'}</th>
                        <th>Payback date</th>
                        <th>Payback period (years)</th>
                        <th>Enterprise NPV (M€)</th>
                        <th>Equity NPV (M€)</th>
                        <th>IRR</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{getFormattedNumber(regulatorData[isHydro ? 'LCOH (€/kg)' : 'LCOE (€/MWh)'], 2)}</td>
                        <td>{formatDateFromString(regulatorData['Payback date'], 'yyyy-MM-dd')}</td>
                        <td>{getFormattedNumber(regulatorData['Payback period'], 1)}</td>
                        <td>{getFormattedNumber(regulatorData['Enterprise NPV']/ 1000000, 1)}</td>
                        <td>{getFormattedNumber(regulatorData['Equity NPV']/ 1000000, 1)}</td>
                        <td>{getFormattedNumber(regulatorData['IRR'] * 100, 2)}</td>
                    </tr>
                    </tbody>
                </Table>
            </fieldset>
        )
    }

    return regulatorData ? (
        <div>
            <h3>{viewName}</h3>
            {summaryInfo}
        </div>
    ) : <h3>Loading...</h3>
}

export default RegulatorSummary;