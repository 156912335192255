import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import AreaChartWrapper from "../../common/AreaChartWrapper";
import BasicTable from "../../common/BasicTable";
import {
    addTotals,
    buildColumnsFromData,
    divideUnitsBy,
    getDateFormatByAggregation
} from "../../commons";
import ChartTableToggle from "../../common/ChartTableToggle";
import {Col, Row} from "react-bootstrap";
import moment from "moment-timezone";

function InvestorBalanceView({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation
                          }){

    const [investorData, setInvestorData] = useState({});
    const [tabMode, setTabMode] = useState("charts");


    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/'+typeName+'?'
                + 'aggregation='+ aggregation))
            .then(res => res.json()).then(data => {
            if(data["data"].length === 1) {
                setInvestorData({...JSON.parse(data["data"][0]["data"])});
            }
        });

    }, [simulationId, aggregation, typeName, simulationInitialValues, simulation.configuration.plants]);

    const yearFrom = simulation.configuration.timePeriod['yearFrom'];
    const yearTo = simulation.configuration.timePeriod['yearTo'];
    let data = [];

    let epoch = new Date(yearFrom + '-01-01').getTime()
    let out = {
        time: epoch,
    }
    Object.keys(investorData).forEach(k => {
        out[k] = ['Debt'].includes(k) ? (-1) * investorData[k][0] : investorData[k][0];
    })
    data.push(out);
    for (let i = yearFrom, indexCounter=1; i <= yearTo; i++, indexCounter++){
        let epoch = new Date(i + '-12-31').getTime()
        let out = {
            time: epoch,
        }
        Object.keys(investorData).forEach(k => {
            out[k] = ['Debt'].includes(k) ? (-1) * investorData[k][indexCounter] : investorData[k][indexCounter];
        })
        data.push(out);
    }

    const tableData = [...data].map(d=>({...d, "time": moment(new Date(d.time)).format(getDateFormatByAggregation("Month"))}));

    const dataByMode = tabMode === "charts" ? (
        <AreaChartWrapper data={divideUnitsBy(data, 1000000)}
                          type={"linear"}
                          title={viewName}
                          brushKey="time"
                          xAxisKey="time"
                          yAxisUnit="M€"
                          aggregation={"MonthEpoch"}/>
    ) : (
        <div style={{marginTop: "15px"}}>
            <BasicTable title={viewName} data={addTotals(tableData)} columns={buildColumnsFromData(tableData)} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>;
        </div>
    );

    return Object.keys(investorData).length > 0 ? (
        <div className="recharts-component">
            <ChartTableToggle mode={tabMode} updateTabMode={setTabMode} updateSelectedTab={()=>{}}/>
            <Row>
                <Col sm={12}>
                    {dataByMode}
                </Col>
            </Row>
        </div>
    ) : <h3>Loading...</h3>
}

export default InvestorBalanceView;