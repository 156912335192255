import {Card, Col, Form, Row} from "react-bootstrap";
import React from "react";

const InvestmentPlants = ({ plant, projectType, index, updatePlant, removePlant }) => {

    return <Card key={index} style={{ width: '95%', padding: '5%' }}>
                <Card.Body>
                    <Card.Title>Plant {plant.index}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Plant type: {plant.type}</Card.Subtitle>
                    <div>
                        <Row>
                            {projectType !== 'Storage' &&
                                <Col sm={6} >
                                    <Form.Label>Project Type
                                        <br/>
                                        <Form.Control as="select" aria-label="Project Type" size="sm" value={plant.type} onChange={event=>{
                                            updatePlant({
                                                ...plant,
                                                type: event.target.value
                                            });
                                        }}>
                                            <option value="Wind">Wind</option>
                                            <option value="Solar PV">PV Solar</option>
                                            <option value="Solar Thermal">Solar Thermal</option>
                                        </Form.Control>
                                    </Form.Label>
                                </Col>
                            }
                            {projectType === 'Storage' &&
                                <Col sm={6} >
                                    <Form.Label>Project Type
                                        <br/>
                                        <Form.Control as="select" aria-label="Project Type" size="sm" value={plant.type} onChange={event=>{
                                            updatePlant({
                                                ...plant,
                                                type: event.target.value
                                            });
                                        }}>
                                            <option value="Storage">Storage</option>
                                        </Form.Control>
                                    </Form.Label>
                                </Col>
                            }
                            <Col sm={6} hidden={projectType !== 'Renewables' && projectType !== 'Storage'}>
                                <Form.Label>Contract type
                                    <Form.Control as="select" aria-label="Project Type" size="sm" value={plant.premiumContract} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            premiumContract: event.target.value
                                        });
                                    }}>
                                        <option value="premium">Feed-in-Premium</option>
                                        <option value="contract">Contract for Differences</option>
                                    </Form.Control>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} >
                                <Form.Label>Plant capacity (MW)
                                    <Form.Control type="number" placeholder="Plant capacity (MW)" size="sm" value={plant.capacity} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            capacity: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={4} >
                                <Form.Label>Plant O&M Cost (€/MWh)
                                    <Form.Control type="number" placeholder="Plant O&M Cost (€/MWh)" size="sm" value={plant.omCost} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            omCost: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={4} hidden={projectType !== 'Renewables' && projectType !== 'Storage'}>
                                <Form.Label>Capacity Payment (€/MW)
                                    <Form.Control type="number" placeholder="Capacity Payment (€/MW)" size="sm" value={plant.regFixed} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            regFixed: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={4} hidden={projectType !== 'Hydrogen'}>
                                <Form.Label>Transfer Price
                                    <Form.Control type="number" placeholder="Transfer Price" size="sm" value={plant.transferPrice} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            transferPrice: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} hidden={projectType !== 'Renewables' && projectType !== 'Storage'}>
                                <Form.Label hidden={plant.premiumContract !== "premium"}>Feed-in-Premium (€/MWh)
                                    <Form.Control type="number" placeholder="Feed-in Premium (€/MWh)" size="sm" value={plant.feedCapacity} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            feedCapacity: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6} hidden={projectType !== 'Storage'}>
                                <Form.Label>Duration
                                    <Form.Control type="number" placeholder="Duration" size="sm" value={plant.duration} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            duration: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row hidden={projectType !== 'Renewables' && projectType !== 'Storage'}>
                            <Col sm={6} >
                                <Form.Label hidden={plant.premiumContract !== "contract"}>Floor price (€/MWh)
                                    <Form.Control type="number" placeholder="Floor price (€/MWh)" size="sm" value={plant.cfdFloor} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            cfdFloor: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6} >
                                <Form.Label hidden={plant.premiumContract !== "contract"}>Market exposure (0-1)
                                    <Form.Control type="number" placeholder="Market exposure (0-1)" size="sm" value={plant.cfdExposure} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            cfdExposure: parseFloat(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6} >
                                <Form.Label hidden={plant.premiumContract !== "contract"}>Strike price (€/MWh)
                                    <Form.Control type="number" placeholder="Strike price (€/MWh)" size="sm" value={plant.cfdStrike} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            cfdStrike: parseInt(event.target.value)
                                        });
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6} >
                                <Form.Label hidden={plant.premiumContract !== "contract"}>Reference price
                                    <Form.Control as="select" aria-label="Reference Price" size="sm" value={plant.cfdReference} onChange={event=>{
                                        updatePlant({
                                            ...plant,
                                            cfdReference: parseInt(event.target.value)
                                        });
                                    }}>
                                        <option value="market">Market</option>
                                    </Form.Control>
                                </Form.Label>
                            </Col>
                        </Row>
                    </div>
                    <Card.Link href="#" style={{color: "blue"}} onClick={()=>{
                        removePlant(plant.index);
                    }}>Remove</Card.Link>
                </Card.Body>
            </Card>

}

export default InvestmentPlants