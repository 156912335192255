import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import ChartTableToggle from "../../common/ChartTableToggle";
import {Col, Row} from "react-bootstrap";
import {ErrorBoundary} from "react-error-boundary";
import {buildColumnsFromData, formatNumbersDecimals, getFormattedNumber} from "../../commons";
import BasicTable from "../../common/BasicTable";
import LineChartWrapper from "../../common/LineChartWrapper";


function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function SensitivityAnalysisView({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation
                          }){

    const [data, setData] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [tabMode, setTabMode] = useState("charts");

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/'+typeName+'?'
                + 'aggregation='+ aggregation))
            .then(res => res.json()).then(data => {
                if(data.data.length > 0) {
                    setData({...JSON.parse(data.data[0].data)});
                    if (simulation.parameters) {
                        const paramsConfig = simulation.parameters.filter(p => p["id"] === parseInt(simulationInitialValues));
                        if (paramsConfig.length === 1) {
                            setInitialValues(JSON.parse(paramsConfig[0].data));
                        }
                    }
                }
            });

    }, [simulationId, aggregation, typeName, simulationInitialValues, simulation.configuration.plants, simulation.parameters]);

    const yAxisLabel = initialValues ? initialValues.dependantVariable : null;
    const xAxisLabel = initialValues ? (initialValues.independentVariable === 'InitialInvestment_{}' ? 'Initial Investment Cost' : initialValues.independentVariable) : null;

    let sensibilityData = [];
    let deviations = {};

    Object.keys(data).forEach(k=>{
        const percentage = getFormattedNumber(k * 100, 1);
        sensibilityData.push({
            [xAxisLabel]: parseFloat(data[k]) * 100,
            time: percentage
        });
        deviations[percentage] = percentage;
    });

    const tableData = formatNumbersDecimals([...sensibilityData]);

    const tabsByMode = tabMode === "charts" ? (
        <LineChartWrapper title={viewName}
                         yAxisUnit={yAxisLabel === "IRR" ? "%" : "€"}
                         data={sensibilityData}
                         yAxisLabel={yAxisLabel}
                         xAxisKey={"time"}
                         aggregation={"label"}
                         />
    ) : (
        <div style={{marginTop: "15px"}}>
            <BasicTable title={viewName} data={tableData} columns={buildColumnsFromData(tableData)} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>;
        </div>
    );


    return tableData.length > 0 ? (
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
            >
                <div className="recharts-component">
                    <ChartTableToggle mode={tabMode} updateTabMode={setTabMode} updateSelectedTab={()=>{}}/>
                    <Row>
                        <Col sm={12}>
                            {tabsByMode}
                        </Col>
                    </Row>
                </div>
            </ErrorBoundary>
    ) : <h3>No data</h3>
}

export default SensitivityAnalysisView;