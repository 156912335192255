import {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Table} from "react-bootstrap";
import {getFormattedNumber} from "../../commons";

function RegulatorHydrogenSummary({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation
                          }){

    const [regulatorData, setRegulatorData] = useState(null);

    useEffect(() => {
        if(simulationId && simulationInitialValues && typeName) {
            trackPromise(
                fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/' + typeName + '?'
                    + 'aggregation=' + aggregation))
                .then(res => res.json()).then(data => {
                if (data["data"].length === 1) {
                    setRegulatorData({...JSON.parse(data["data"][0]["data"])});
                }
            });
        }
    }, [simulationId, aggregation, typeName, simulationInitialValues]);


    let summaryInfo = null;
    if (regulatorData && viewName === "Regulator's view - Summary"){
        summaryInfo = (
            <div>
                <fieldset>
                    <legend className={"text-left"}>
                        H2 plant metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Total H2 production (tons)</th>
                            <th>Average Electricity Price from Grid (€/Mwh)</th>
                            <th>Average Electricity Price from Plants (€/Mwh)</th>
                            <th>Average Consumption (MWh)</th>
                            <th>Average Consumption from grid (MWh)</th>
                            <th>Average Consumption from plants (MWh)</th>
                            <th>Average Cost H2 (€/Kg)</th>
                            <th>Variable Cost H2 (€/Kg)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{getFormattedNumber(regulatorData['Total H2 production (tons)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Electricity Price from Grid (€/Mwh)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Electricity Price from Plants (€/Mwh)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Consumption (MW)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Consumption from grid (MW)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Consumption from plants (MW)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average cost H2'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Variable cost H2'], 2)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>

                <fieldset>
                    <legend className={"text-left"}>
                        Renewable Plants' metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Plant</th>
                            <th>Average price received (€/MWh)</th>
                            <th>Captured market price (€/MWh)</th>
                            <th>Average cost (€/MWh)</th>
                            <th>Variable cost (€/MWh)</th>
                            <th>Avg production to H2 (MWh)</th>
                            <th>Avg production to Grid (MWh)</th>
                            <th>Average Excess Production (MWh)</th>
                            <th>Avg production to H2 (%)</th>
                            <th>Avg production to Grid (%)</th>
                            <th>Avg excess production (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Project</td>
                            <td>{getFormattedNumber(regulatorData['Average price received']["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Captured market price']["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average cost"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Variable cost"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Production to H2 (MWh)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Production to Grid (MWh)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Excess Production (MWh)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Production to H2 (%)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Production to Grid (%)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Excess Production (%)"]["Total"], 2)}</td>
                        </tr>
                        {
                            simulation.configuration.plants.map((p, idx) => {
                                const plant_index = p['index'];
                                return (
                                    <tr key={idx}>
                                        <td>{p['type']}</td>
                                        <td>{getFormattedNumber(regulatorData['Average price received']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Captured market price']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average cost']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Variable cost']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average Production to H2 (MWh)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average Production to Grid (MWh)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average Excess Production (MWh)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average Production to H2 (%)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average Production to Grid (%)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average Excess Production (%)']['VAL_' + plant_index], 2)}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </Table>
                </fieldset>

                <fieldset>
                    <legend className={"text-left"}>
                        Regulator's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>NPV of regulator revenue (M€)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{getFormattedNumber(regulatorData['NPV of regulator revenue'] / 1000000, 0)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>
            </div>
        )
    }

    if (regulatorData && viewName === "Investor's view - Summary") {
        summaryInfo = (
            <fieldset>
                <legend className={"text-left"}>
                    Project's metrics
                </legend>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>LCOH (€/MWh)</th>
                        <th>Payback date</th>
                        <th>Payback period (years)</th>
                        <th>Enterprise NPV (M€)</th>
                        <th>Equity NPV (M€)</th>
                        <th>IRR</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{getFormattedNumber(regulatorData['LCOH (€/kg)'], 2)}</td>
                        <td>{regulatorData['Payback date']}</td>
                        <td>{regulatorData['Payback period']}</td>
                        <td>{getFormattedNumber(regulatorData['Enterprise NPV'] / 1000000, 2)}</td>
                        <td>{getFormattedNumber(regulatorData['Equity NPV'] / 1000000, 2)}</td>
                        <td>{getFormattedNumber(regulatorData['IRR'], 2)}</td>
                    </tr>
                    </tbody>
                </Table>
            </fieldset>
        )
    }

    return regulatorData ? (
        <div>
            <h3>{viewName}</h3>
            {summaryInfo}
        </div>
    ) : <h3>Loading...</h3>
}

export default RegulatorHydrogenSummary;